@use "@picocss/pico/scss/pico" as theme;
@use "functions" as f;
@use "vars" as v;

:root {
    --pico-spacing-l: calc(var(--pico-spacing) * 2);
}

.button {
    @extend button;
}

button,
.button {
    &.danger {
        --pico-border-color: var(--pico-form-element-invalid-border-color);
        --pico-background-color: var(--color-danger);
        --pico-color: var(--pico-contrast-inverse);

        &.outline {
            --pico-background-color: transparent;
            --pico-color: var(--color-danger);
            &:hover {
                --pico-border-color: var(--pico-form-element-invalid-border-color);
                --pico-background-color: var(--color-danger);
                --pico-color: var(--pico-contrast-inverse);
            }
        }
    }

    &.small {
        padding: calc(var(--pico-form-element-spacing-vertical) / 2)
            calc(var(--pico-form-element-spacing-horizontal) / 2);
        font-size: 0.75rem;
    }
}

// pico dropdown
details.dropdown.small {
    summary {
        padding: calc(var(--pico-form-element-spacing-vertical) / 2)
            calc(var(--pico-form-element-spacing-horizontal) / 2);
        font-size: 0.75rem;
        height: auto;
    }
}

.dropdown ul a {
    cursor: pointer;
}

details.dropdown.with-icon summary:after {
    display: none;
}

/* layout */

@media (min-width: 1536px) {
    .container-fluid--restricted {
        max-width: 1450px;
    }
}

.grid--left-sidebar {
    @extend .grid;

    @media (min-width: 768px) {
        grid-template-columns: auto 3fr;
        gap: 2rem;
    }
}

.grid-3 {
    @extend .grid;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.l-half {
    max-width: 30rem;
}

.l-narrow {
    width: 25rem;
    max-width: 100%;
}

.no-shrink {
    flex-shrink: 0;
}

.float-l {
    float: left;
}

.float-r {
    float: right;
}

.float-r,
.float-l {
    :not(td):has(> &) {
        display: flow-root;
    }
}

.w-full {
    width: 100%;
    &.negate-spacing-horizontal {
        width: calc(100% + (var(--pico-block-spacing-horizontal) * 2));
    }
}

.bdr-none {
    border-radius: 0;
}

.inline {
    display: inline;
}

/* flex utils */
.flex {
    display: flex;
}

.flex--wrap {
    flex-wrap: wrap;
}

.gap {
    gap: var(--pico-block-spacing-horizontal);
}

.gap-xl {
    gap: calc(var(--pico-block-spacing-horizontal) * 2);
}

.align-start {
    align-items: start;
}

.align-center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

/* spacing */
.negate-spacing-t {
    margin-top: f.n(var(--pico-block-spacing-vertical));
}

.negate-spacing-r {
    margin-right: f.n(var(--pico-block-spacing-horizontal));
}

.negate-spacing-horizontal {
    margin-left: f.n(var(--pico-block-spacing-horizontal));
    margin-right: f.n(var(--pico-block-spacing-horizontal));
}

.negate-spacing-vertical {
    margin-top: f.n(var(--pico-block-spacing-vertical));
    margin-bottom: f.n(var(--pico-block-spacing-vertical));
}

.inset-v-sm {
    padding-top: calc(var(--pico-block-spacing-vertical) / 2);
    padding-bottom: calc(var(--pico-block-spacing-vertical) / 2);
}

.inset-h {
    @media (width >= #{v.$desktop-menu}) {
        padding-left: var(--pico-block-spacing-vertical);
        padding-right: var(--pico-block-spacing-vertical);
    }
}

.spacing-v {
    margin-top: var(--pico-block-spacing-vertical);
    margin-bottom: var(--pico-block-spacing-vertical);
}

.spacing-v-sm {
    margin-top: calc(var(--pico-block-spacing-vertical) / 2);
    margin-bottom: calc(var(--pico-block-spacing-vertical) / 2);
}

.spacing-v-xl {
    margin-top: calc(var(--pico-block-spacing-vertical) * 2);
    margin-bottom: calc(var(--pico-block-spacing-vertical) * 2);
}

.spacing-r-xl {
    margin-right: calc(var(--pico-block-spacing-horizontal) * 2);
}

.spacing-t {
    margin-top: var(--pico-block-spacing-vertical);
}

.spacing-t-sm {
    margin-top: calc(var(--pico-block-spacing-vertical) / 2);
}

.spacing-t-xs {
    margin-top: calc(var(--pico-block-spacing-vertical) / 3);
}

.spacing-b {
    margin-bottom: var(--pico-block-spacing-vertical);
}

.spacing-zero-b {
    margin-bottom: 0;
}

/* typography */
.t-center {
    text-align: center;
}

.muted {
    color: var(--pico-muted-color);
}

.t-underline {
    position: relative;
    z-index: 0; // otherwise could end up overlapping floated elements

    &:after {
        content: "";
        display: block;
        width: 25%;
        max-width: 100px;
        margin-bottom: 3px;
        inset: auto 0 0;
        border-bottom: 3px solid var(--pico-contrast);
    }
}

.t-xsmall {
    font-size: 0.7rem;
}

.overflow-horizontal {
    overflow-x: auto;
}

.mobile-overflow-horizontal {
    @media (width < #{v.$desktop-menu}) {
        overflow-x: auto;
    }
}

.border-bottom {
    border-bottom: 2px solid var(--alta-kobicha);
}

/* responsive */
.view-mobile {
    @media (width >= #{v.$desktop-menu}) {
        display: none;
    }
}

.view-desktop {
    @media (width < #{v.$desktop-menu}) {
        display: none;
    }
}

.view-large-screen {
    @media (width < #{v.$large-screen}) {
        display: none;
    }
}

/* bootstrap */
.text-right {
    text-align: right;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #007bff;
}

.badge-primary {
    background-color: var(--pico-primary);
}

.badge-secondary {
    background-color: var(--pico-secondary);
}

.badge-success {
    background-color: var(--pico-primary);
}

.badge-danger {
    background-color: #dc3545;
}

.badge-warning {
    background-color: #ffc107;
    color: #212529;
}

.badge-info {
    background-color: var(--pico-contrast);
}

.badge-light {
    background-color: #f8f9fa;
    color: #212529;
}

.badge-dark {
    background-color: #343a40;
}

.u-icon {
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
    }
}

.item--active {
    font-weight: bold;
}

.item--inactive {
    opacity: 0.75;
}

.invisible-input {
    input {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        white-space: nowrap;
        clip-path: inset(50%);
    }
}
