@use "@picocss/pico/scss/pico";

@use "colors";


:root {
    --pico-block-spacing-vertical: 3.5rem;
}

.ghost {
    padding: 0;
    border: 0;
    font-weight: bold;
}

details.dropdown.position-left summary + ul {
    left: auto;
    right: 0;
}