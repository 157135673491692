@use "custom_pico" as theme;
@use "functions" as f;
@use "utils";
@use "colors" as c;
@use "vars" as v;
@use "ck_editor";
@use "sass:color";

// write mapping of z-indexes
$zIndexes: (
    "sticky-bar": 5,
    "basket-dropdown": 10,
    "alert": 20,
    "header": 30,
    "loader": 40
);

html {
    height: 100%;
}
body {
    min-height: 100%;
}

small {
    color: var(--pico-color);
}

button {
    white-space: nowrap;
}

// alpine.js hide on load style
[x-cloak] {
    display: none !important;
}

iframe {
    max-width: 100%;
    height: auto;
}

blockquote {
    font-size: 1.25rem;
    font-style: italic;
    p:last-child {
        font-style: italic;
        margin-bottom: 0;
    }
}

svg {
    max-width: 100%;
}

.default-page > main {
    padding-block-start: 0;
    & > :first-child {
        padding-top: 0;
    }
}

.flex-page {
    display: flex;
    flex-direction: column;

    & > main {
        padding-block-start: 0;
        flex-grow: 1;
    }
}

.site-header {
    position: sticky;
    top: 0;
    --pico-block-spacing-vertical: 0.75rem;
    margin-bottom: var(--pico-block-spacing-vertical);
    background-color: var(--pico-background-color);

    z-index: map-get($zIndexes, "header");

    .scrolled & {
        transition: box-shadow 1s;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
}

.page-hero {
    width: 100%;
    img {
        width: 100%;
        height: auto;
        max-height: 400px;
        object-fit: cover;
    }
}

nav.main-nav {
    flex-wrap: wrap;

    .alta {
        @extend .spacing-r-xl;
        width: 70px;
        transition: width 0.3s;
        .scrolled & {
            width: 50px;
        }

        @media (width >= #{v.$desktop-menu}) {
            width: 100px;
            .scrolled & {
                width: 60px;
            }
        }
    }

    .secondary > li {
        transition: padding 0.3s;

        .scrolled & {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @media (width >= #{v.$desktop-menu}) {
        .scrolled & > ul > li {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .main {
        display: none;
        &.is-open {
            display: flex;
        }
        margin-right: auto;
        order: 3;
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        margin: 0;

        li {
            text-align: center;
        }

        a {
            display: block;
            background-color: #{rgba(c.$alta-gold, 0.25)};
        }

        @media (width >= #{v.$desktop-menu}) {
            display: flex;
            order: inherit;
            width: auto;
            flex-direction: row;
            align-items: center;
            margin-right: auto;

            a {
                background-color: transparent;
            }
        }
    }

    // overriding pico styles
    details.dropdown {
        margin: 0;
    }
}

.basket-menu-item {
    @media (width >= #{v.$desktop-menu}) {
        position: relative;
    }
}

.basket-dropdown {
    position: absolute;
    inset: auto 10% auto;
    background-color: var(--alta-gold--lighter);
    // get scss value from scss map
    z-index: map-get($zIndexes, "basket-dropdown");
    padding: var(--pico-spacing);
    border: 1px solid var(--pico-color);
    border-radius: var(--pico-border-radius);
    @media (width >= #{v.$desktop-menu}) {
        top: 100%;
        left: auto;
        bottom: auto;
        right: 10px;
        width: 400px;
    }
}

.basket-mini-item {
    display: block;
    font-size: 14px;

    li {
        display: block;
        border-bottom: 1px solid var(--pico-secondary-border);
        &:last-child {
            border-bottom: 0;
        }
    }

    .product-row {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0.5rem;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.hero {
    position: relative;
    padding: 2rem;
    overflow: hidden;

    .hero__content {
        float: right;
        padding: 15% 10% 5%;
        text-align: right;
        max-width: 50rem;
        --pico-color: var(--pico-secondary-inverse);
        h1 {
            --pico-color: var(--pico-secondary-inverse);
        }
    }

    .hero__cta {
        display: inline-block;
        margin-top: 2rem;
    }

    img {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
        object-fit: cover;
        object-position: top;
    }

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--pico-secondary-background);
        opacity: 0.4;
        z-index: -1;
    }
}

article.featured {
    margin-bottom: 0;
    padding-bottom: calc(var(--pico-block-spacing-vertical) / 2);
    --pico-card-background-color: var(--alta-white);
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 1rem;

    figure {
        @extend .negate-spacing-t;
        @extend .negate-spacing-horizontal;
        margin-bottom: var(--pico-typography-spacing-vertical);
    }

    img {
        width: 100%;
        aspect-ratio: 4 / 3;
        object-fit: cover;
        object-position: center;
    }
}

article.featured--coffee {
    display: flex;
    flex-direction: column;
    figure {
        position: relative;
    }

    .featured__overlay {
        position: absolute;
        inset: 0;
        padding: var(--pico-spacing);
        background-color: #{rgba(c.$alta-charcoal, 0.8)};
        overflow: hidden;
        display: none;
    }

    .featured__title {
        margin: 0;
        font-weight: 500;
        a {
            text-decoration: none;
            --pico-color: var(--alta-rich-black);
        }
    }

    .featured__price-wrap {
        margin: 0;
        margin-top: auto;
        font-size: 0.8rem;
    }

    .featured__price {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .view-button {
        border-color: #0f131e;
        color: #0f131e;
    }

    .view-button,
    button {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
        letter-spacing: 0.7px;
        border-radius: 5px;
        padding: 0.65rem;
    }

    .product__info {
        width: 100%;
        font-size: 0.8rem;
        table,
        table td,
        table th {
            background-color: transparent;
        }
        th {
            vertical-align: top;
            padding-left: 0;
        }
        ul,
        li {
            list-style: none;
            padding-left: 0;
        }

        li {
            display: flex;
        }

        th {
            color: var(--pico-contrast);
            font-size: 0.7rem;
            font-style: normal;
            // font-weight: ;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

.banner {
    padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
    background-color: var(--pico-primary-background);
    &,
    h2 {
        --pico-color: var(--pico-contrast-inverse);
    }
}

.banner--narrow {
    padding-left: 15%;
    padding-right: 15%;
}

.page-footer {
    &,
    a,
    h4 {
        --pico-color: var(--pico-secondary-inverse);
    }

    background-color: var(--pico-secondary-background);

    a {
        display: inline-block;
    }
}

.product-single {
    padding-bottom: 0;
    --pico-card-box-shadow: none;

    padding: 0;

    @media (width >= #{v.$desktop-menu}) {
        padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
        padding-left: 0;
    }

    .product-single__image-wrap {
        position: relative;
        min-height: 250px;
        margin-left: f.n(var(--pico-spacing));
        margin-right: f.n(var(--pico-spacing));
        @media (width >= #{v.$desktop-menu}) {
            min-height: 550px;
            margin-right: 0;
        }

        overflow: hidden;
        img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .product-single__detail {
        display: flex;
        flex-direction: column;
        padding: 0 var(--pico-block-spacing-horizontal);
        order: 2;
    }

    .product-single__title {
        font-weight: normal;
        font-size: 2.5rem;
    }

}

.product-single__price {
    font-size: 1.5rem;
    font-weight: 400;
}

.product-hgroup {
    display: grid;
    grid-template-areas:
        "title logo"
        "vendor logo";

    .product-hgroup__title {
        grid-area: title;
    }
    .product-hgroup__vendor {
        grid-area: vendor;
        font-size: 0.8rem;
    }
    .product-hgroup__logo {
        grid-area: logo;
        justify-self: end;
        max-width: 60px;
    }
}

.add-to-cart-modal {
    // position: relative;
    button[rel="prev"] {
        position: absolute;
        top: 10px;
        right: 10px;
        margin-top: 0 !important;
    }

    article {
        position: relative;
        margin: 0;
    }
}

.order-form {
    margin-top: auto;

    @media (width < #{v.$desktop-menu}) {
        .form-field {
            .form-layout__item:not(:first-child) {
                flex-grow: 1;
                select {
                    width: 100%;
                }
            }
        }
    }

    .add-to-cart {
        --pico-border-radius: 0.6rem;
        --pico-spacing: 0;
        text-transform: uppercase;
        width: 100%;
    }
}

.form-layout {
    .form-field {
        display: grid;
        @media (width >= #{v.$laptop-screen}) {
            grid-template-areas: var(--grid-template-areas);
        }
    }

    .form-layout__item {
        @media (width >= #{v.$laptop-screen}) {
            grid-area: var(--field-name);
        }

        input,
        select {
            width: 100%;
        }
    }
}

.order-form {
    .form-field {
        display: flex;
        flex-wrap: wrap;

        select,
        input {
            width: auto;
        }
    }
}

.order-form,
.form--shipping-address {
    .form-field {
        margin: calc(var(--pico-block-spacing-vertical) / 2) 0;
        gap: calc(var(--pico-spacing) * 2);

        label {
            --pico-color: var(--pico-secondary);
            font-size: 0.8rem;
            text-transform: uppercase;
        }

        select,
        input {
            --pico-spacing: 0;
            font-size: 1rem;
            padding: 3px;
            height: auto;
        }

        select {
            padding-right: 2.2rem;
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid var(--pico-contrast);

            &:focus {
                --pico-box-shadow: 0 2px 0 var(--pico-contrast);
            }
        }

        .quantity-input-wrap {
            display: flex;
            justify-content: space-around;
            align-items: center;
            input {
                transform: translateX(7px);
                text-align: center;
                border: 0;
                width: 2rem;
                -moz-appearance: textfield;
            }
        }

        button.quantity__button {
            position: relative;
            --pico-background-color: transparent;
            --pico-border-color: transparent;
            --pico-color: var(--pico-secondary);
            --pico-box-shadow: none;
            --button-size: 1.2rem;
            padding: 0;
            border-radius: 2rem;
            width: var(--button-size);
            height: var(--button-size);
            line-height: 0;
            font-size: 15px;
            margin: 0;

            &:active {
                transform: scale(0.9);
            }

            svg {
                width: var(--button-size);
            }
        }
    }
}

.form-field__help {
    display: block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* template error in a form */
.alert {
    border-left: 3px solid var(--pico-contrast);
    padding-left: var(--pico-spacing);
    &.alert-danger {
        border-left-color: var(--color-danger);
        color: var(--pico-del-color);
    }
}

.error-block {
    display: block;
    font-size: 0.8em;
    margin: 0 auto;
    color: var(--pico-del-color);
}

.card {
    --pico-card-background-color: var(--alta-white);
    border-radius: 14px;
}

.info-detail {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: start;
    justify-content: start;
    justify-items: start;
    align-content: start;

    margin-top: var(--pico-spacing);

    gap: calc(var(--pico-spacing) * 1.5);

    dt {
        text-transform: uppercase;
        font-weight: bold;
    }

    dd {
        margin-left: 0;
        font-size: 0.8rem;
    }
}

.highlight-banner {
    display: flex;
    align-items: center;
    background: var(--alta-gold--lighter);
    border-left: 5px solid var(--pico-contrast);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin: 1rem 0;

    p {
        font-size: 0.75rem;
        margin-bottom: 0;
    }
}

.page-messages {
    position: fixed;
    bottom: 5%;
    right: 0;
    max-width: 350px;
    z-index: map-get($zIndexes, "alert");

    .alert {
        position: relative;
        @extend .inset-v-sm;
        border-left: 4px solid var(--pico-contrast);
        font-size: 0.8rem;
        margin-bottom: 1rem;

        p:last-child {
            margin-bottom: 0;
        }

        .alert__close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 5px;
            right: 5px;
            padding: 2px;
            line-height: 0;
            width: 18px;
            height: 18px;
            &:before {
                content: "×";
                font-size: 12px;
            }
        }
    }
}

// Breadcrumbs for checkout and account
[aria-label="breadcrumb"] {
    .active {
        text-decoration: underline;
    }
}

.inline-fieldset {
    margin-bottom: 0;
}

.cart__price {
    color: var(--pico-color);
}

table.striped {
    tr > td {
        border-bottom: 0;
    }
}

.sticky-bar {
    position: sticky;
    bottom: 0;
    background-color: var(--alta-gold--lighter);
    padding: var(--pico-spacing);
    z-index: map-get($zIndexes, "sticky-bar");

    button {
        margin-bottom: 0;
    }
}

.dropdowns {
    ul,
    li {
        list-style-type: none;
        padding-left: 0;
        font-size: 0.8rem;
    }

    [type="checkbox"] {
        width: 1em;
        height: 1em;
    }

    li {
        white-space: nowrap;
    }

    .dropdowns__button {
        &:after {
            // css arrow
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid var(--pico-color);
            margin-left: 0.5rem;
            transform: translateY(-50%);
            transition: transform 0.3s;
        }

        &.active {
            --pico-color: var(--pico-contrast);
            &:after {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
}

.htmx-request:not(body) {
    opacity: 0.7;
}

.loader-wrap {
    .htmx-request & {
        display: block;
    }
    z-index: map-get($zIndexes, "loader");
    display: none;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fffe;
    width: 5em;
    height: 2.25em;
    border-radius: 2.5em;
}

.loader,
.loader:before,
.loader:after {
    display: block;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
    color: var(--pico-contrast);
    font-size: 7px;
    position: relative;
    top: -0.65em;
    left: 5.5em;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
