@use "sass:color";

$alta-rich-black: #0f131e;
$alta-charcoal: #264653;
$alta-bone: #eee3d0;
$alta-gold: #d18800;
$alta-gold--lighter: #e9d3af;
$alta-kobicha: #654321;

[data-theme="light"],
:root:not([data-theme="dark"]) {
    // Custom
    --alta-rich-black: #{$alta-rich-black};
    --alta-charcoal: #{$alta-charcoal};
    --alta-bone: #{$alta-bone};
    --alta-gold: #{$alta-gold};
    --alta-gold--lighter: #{$alta-gold--lighter};
    --alta-kobicha: #{$alta-kobicha};
    --alta-white: #f7f3eb;
    --color-danger: #c81012;

    // pico edited colours
    --pico-background-color: #{color.adjust($alta-bone, $lightness: 0.85%)};
    --pico-color: var(--alta-rich-black);
    --pico-text-selection-color: #{rgba($alta-gold, 0.25)};
    --pico-muted-color: #646b79;
    --pico-muted-border-color: #cb9f4d;
    --pico-primary: var(--alta-charcoal);
    --pico-primary-background: var(--alta-charcoal);
    --pico-primary-border: var(--pico-primary-background);
    --pico-primary-underline: #{rgba($alta-charcoal, 0.5)};
    --pico-primary-hover: var(--alta-gold);
    --pico-primary-hover-background: #{color.scale($alta-charcoal, $blackness: 10%)};
    --pico-primary-hover-border: var(--pico-primary-hover-background);
    --pico-primary-hover-underline: var(--pico-primary-hover);
    --pico-primary-focus: #{rgba($alta-gold, 0.5)};
    --pico-primary-inverse: var(--alta-bone);
    --pico-secondary: var(--alta-kobicha);
    --pico-secondary-background: var(--pico-secondary);
    --pico-secondary-border: var(--pico-secondary-background);
    --pico-secondary-underline: rgba(93, 107, 137, 0.5);
    --pico-secondary-hover: #48536b;
    --pico-secondary-hover-background: #48536b;
    --pico-secondary-hover-border: var(--pico-secondary-hover-background);
    --pico-secondary-hover-underline: var(--pico-secondary-hover);
    --pico-secondary-focus: rgba(93, 107, 137, 0.25);
    --pico-secondary-inverse: var(--alta-bone);
    --pico-contrast: var(--alta-gold);
    --pico-contrast-background: var(--alta-gold);
    --pico-contrast-border: var(--pico-contrast-background);
    --pico-contrast-underline: #{rgba($alta-gold, 0.5)};
    --pico-contrast-hover: #000;
    --pico-contrast-hover-background: #{color.scale($alta-gold, $blackness: 10%)};
    --pico-contrast-hover-border: var(--pico-contrast-hover-background);
    --pico-contrast-hover-underline: var(--pico-secondary-hover);
    --pico-contrast-focus: rgba(93, 107, 137, 0.25);
    --pico-contrast-inverse: var(--alta-bone);
    --pico-box-shadow: 0.0145rem 0.029rem 0.174rem rgba(129, 145, 181, 0.01698),
        0.0335rem 0.067rem 0.402rem rgba(129, 145, 181, 0.024), 0.0625rem 0.125rem 0.75rem rgba(129, 145, 181, 0.03),
        0.1125rem 0.225rem 1.35rem rgba(129, 145, 181, 0.036), 0.2085rem 0.417rem 2.502rem rgba(129, 145, 181, 0.04302),
        0.5rem 1rem 6rem rgba(129, 145, 181, 0.06), 0 0 0 0.0625rem rgba(129, 145, 181, 0.015);
    --pico-h1-color: var(--alta-rich-black);
    --pico-h2-color: #373c44;
    --pico-h3-color: #424751;
    --pico-h4-color: #4d535e;
    --pico-h5-color: #5c6370;
    --pico-h6-color: #646b79;
    --pico-mark-background-color: #fde7c0;
    --pico-mark-color: #0f1114;
    --pico-ins-color: #1d6a54;
    --pico-del-color: #883935;
    --pico-blockquote-border-color: var(--pico-contrast);
    --pico-blockquote-footer-color: var(--pico-muted-color);
    --pico-button-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --pico-button-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --pico-table-border-color: #{rgba($alta-gold, 0.2)};
    --pico-table-row-stripped-background-color: rgba(111, 120, 135, 0.0375);
    --pico-code-background-color: #f3f5f7;
    --pico-code-color: #646b79;
    --pico-code-kbd-background-color: var(--pico-color);
    --pico-code-kbd-color: var(--pico-background-color);
    --pico-form-element-background-color: var(--alta-bone);
    --pico-form-element-selected-background-color: #dfe3eb;
    --pico-form-element-border-color: #cfd5e2;
    --pico-form-element-color: #23262c;
    --pico-form-element-placeholder-color: var(--pico-muted-color);
    --pico-form-element-active-background-color: #{rgba($alta-bone, 0.75)};
    --pico-form-element-active-border-color: var(--pico-primary-border);
    --pico-form-element-focus-color: var(--pico-primary-border);
    --pico-form-element-disabled-opacity: 0.5;
    --pico-form-element-invalid-border-color: #b86a6b;
    --pico-form-element-invalid-active-border-color: #c84f48;
    --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
    --pico-form-element-valid-border-color: #4c9b8a;
    --pico-form-element-valid-active-border-color: #279977;
    --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
    --pico-switch-background-color: #bfc7d9;
    --pico-switch-checked-background-color: var(--pico-primary-background);
    --pico-switch-color: #fff;
    --pico-switch-thumb-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --pico-range-border-color: #dfe3eb;
    --pico-range-active-border-color: #bfc7d9;
    --pico-range-thumb-border-color: var(--pico-background-color);
    --pico-range-thumb-color: var(--pico-secondary-background);
    --pico-range-thumb-active-color: var(--pico-primary-background);
    --pico-accordion-border-color: var(--pico-muted-border-color);
    --pico-accordion-active-summary-color: var(--pico-primary);
    --pico-accordion-close-summary-color: var(--pico-color);
    --pico-accordion-open-summary-color: var(--pico-muted-color);
    --pico-card-background-color: var(--pico-background-color);
    --pico-card-border-color: var(--pico-muted-border-color);
    --pico-card-box-shadow: var(--pico-box-shadow);
    --pico-card-sectioning-background-color: #fbfcfc;
    --pico-dropdown-background-color: #fff;
    --pico-dropdown-border-color: #eff1f4;
    --pico-dropdown-box-shadow: var(--pico-box-shadow);
    --pico-dropdown-color: var(--pico-color);
    --pico-dropdown-hover-background-color: #eff1f4;
    --pico-loading-spinner-opacity: 0.5;
    --pico-modal-overlay-background-color: rgba(232, 234, 237, 0.75);
    --pico-progress-background-color: #dfe3eb;
    --pico-progress-color: var(--pico-primary-background);
    --pico-tooltip-background-color: var(--pico-contrast-background);
    --pico-tooltip-color: var(--pico-contrast-inverse);
    --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(76, 155, 138)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(200, 79, 72)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    color-scheme: light;
}
